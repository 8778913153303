import "./index.scss";
import { a, b, c, d } from "../../assets/images/lecture_images";
function End_lecture() {
  return (
    <div className="end_lecture_container">
      <div className="title">
        <h1>以往課程</h1>
        <h1>end lecture</h1>
      </div>
      <div className="end_lecture_photos">
        <img src={a} alt="" />
        <img src={b} alt="" />
        <img src={c} alt="" />
        <img src={d} alt="" />
        <img src={a} alt="" />
        <img src={b} alt="" />
        <img src={c} alt="" />
        <img src={d} alt="" />
      </div>
    </div>
  );
}

export default End_lecture;
