import "./index.scss";
function Banner() {
  return (
    <div className="banner_container">
        <div className="row">
      <div className="title">
        <h1>台北科技大學 區塊鏈研究社</h1>
        <h2>NTUT Blockchain Club</h2>
        <a href="https://www.facebook.com/NTUTBlockchainClub/">
          加入我們!</a>
        </div>

      </div>
    </div>
  );
}
export default Banner;
